import theme from '@ifca-root/react-component/src/assets/theme'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import SwitchBar from '@ifca-root/react-component/src/components/SpecialBar/SwitchBar'
import { useMediaQuery } from '@material-ui/core'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import {
  useGetArScheduledBillingbyStatusListingLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ARScheduledBillingEntryList } from './ARScheduledBillingList/ARScheduledBillingEntryList'
import { ARScheduledBillingPostingList } from './ARScheduledBillingList/ARScheduledBillingPostingList'

export const ARScheduledBillingMain = (props: any) => {
  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const approvalStatusEnum = [
    { name: 'Scheduled', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Approved', ID: 'COMPLETED' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))
  const [openExitConf, setOpenExitConf] = useState(null)
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    loadScheduledBilling,
    {
      loading: ScheduledBillingbyStatusLoading,
      data: { getARScheduledBillingbyStatusListing } = {
        getARScheduledBillingbyStatusListing: [],
      },
    },
  ] = useGetArScheduledBillingbyStatusListingLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getARScheduledBillingbyStatusListing }) => {
      if (getARScheduledBillingbyStatusListing?.Data?.length > 0) {
        setOriginalListing([
          ...filteredList,
          ...getARScheduledBillingbyStatusListing?.Data,
        ])
      }
    },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  /* ------------------------------------------ */
  /*                    VARIABLE                */
  /* ------------------------------------------ */

  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()

  const hasChanges = () => {
    if (postIDs?.size === 0) return false
    else return true
  }

  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadScheduledBilling({
          variables: {
            CompanyID: CompanyID,
            StatusArr: [listStatus],
            take: 30,
            skip: 0,
            searchValue: change,
          },
        })
      }, 1000)
    )
  }

  useEffect(() => {
    setOriginalListing([])
    loadScheduledBilling({
      variables: {
        CompanyID: CompanyID,
        StatusArr: [listStatus],
        take: 30,
        skip: 0,
        searchValue:
          getSearch?.name !== '' || getSearch?.name === undefined
            ? undefined
            : getSearch?.name,
      },
    })
  }, [listStatus])

  return (
    <>
      {UserLoading && <Loading />}
      {ScheduledBillingbyStatusLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasChanges() === true && listStatus === 'SUBMIT'
            ? setOpenExitConf(true)
            : history.push(`/account-receivable/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }}
        smTitle="Accounts Receivable"
        title={user?.companyName}
        routeSegments={[
          { name: 'Account Receivable' },
          { name: 'Scheduled Billing', current: true },
        ]}
      />
      <SwitchBar
        options={approvalStatusEnum}
        setOptionID={setListStatus}
        borderRadius="4px"
        defaultOption={approvalStatusEnum
          ?.map(opt => opt?.ID)
          .indexOf(listStatus ?? 'ACTIVE')}
        searchFilter={getSearch}
      />
      <div
        className="search-filter with-dropdown-filter"
        style={{
          marginTop: isDesktop ? '-64px' : '0px',
          marginBottom: '-8px',
        }}
      >
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
            changeDelay(e?.target?.value)
          }}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
            loadScheduledBilling({
              variables: {
                CompanyID: CompanyID,
                StatusArr: [listStatus],
                take: 30,
                skip: 0,
                searchValue:
                  getSearch?.name !== '' || getSearch?.name === undefined
                    ? undefined
                    : getSearch?.name,
              },
            })
            localStorage.removeItem('searchFilter')
          }}
        />
      </div>

      {(listStatus === 'ACTIVE' || listStatus === 'COMPLETED') && (
        <ARScheduledBillingEntryList
          filteredList={filteredList}
          ScheduledBillingbyStatusLoading={ScheduledBillingbyStatusLoading}
          listStatus={listStatus}
          userList={getUsersByAccountAndSoftware}
          fetchMore={loadScheduledBilling}
          setOriginalListing={setOriginalListing}
          errMessage={errMessage}
          setErrMessage={setErrMessage}
          errDialog={errDialog}
          setErrDialog={setErrDialog}
        />
      )}
      {listStatus === 'SUBMIT' && (
        <ARScheduledBillingPostingList
          filteredList={filteredList}
          ScheduledBillingbyStatusLoading={ScheduledBillingbyStatusLoading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={getUsersByAccountAndSoftware}
          setOriginalListing={setOriginalListing}
          fetchMore={loadScheduledBilling}
          errMessage={errMessage}
          setErrMessage={setErrMessage}
          errDialog={errDialog}
          setErrDialog={setErrDialog}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-receivable/${CompanyID}`)
        }}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
