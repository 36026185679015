import theme from '@ifca-root/react-component/src/assets/theme'
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Avatar,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  useMediaQuery,
  withStyles,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { RejectDialog } from 'components/Dialog/RejectDialog'
import { SubmitterDialog } from 'components/Dialog/SubmitterDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import { AccountFooter } from 'components/Footer/AccountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  GetArScheduledBillingbyStatusListingDocument,
  usePostApprovedArScheduledBillingMutation,
  useRejectArScheduledBillingMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import {
  formatDashDate,
  formatDate,
} from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router'
import { ARScheduledBillingDetailContent } from '../ARScheduledBillingDetail/ARScheduledBillingDetailContent'

export const ARScheduledBillingPostingList = (props: any) => {
  const {
    filteredList,
    ScheduledBillingbyStatusLoading,
    listStatus,
    postIDs,
    setPostIDs,
    userList,
    setOriginalListing,
    fetchMore,
    setErrMessage,
    setErrDialog,
  } = props

  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()
  const { menu: menu2 }: any = useMenuOption()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [openDialog, setOpenDialog] = useState(false)
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [clickReject, setIsReject] = useState(false)
  const [rejectDialogInput, setRejectDialogInput] = useState({
    RejectionRemark: '',
  })
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const { handleSubmit } = useForm({
    mode: 'onSubmit',
  })

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    postARScheduledBilling,
    { loading: postARScheduledBillingLoading },
  ] = usePostApprovedArScheduledBillingMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])
      fetchMore({
        variables: {
          CompanyID: CompanyID,
          StatusArr: [listStatus],
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name === '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    rejectScheduledBilling,
    { loading: rejectJournalProcessingLoading },
  ] = useRejectArScheduledBillingMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])
      fetchMore({
        variables: {
          CompanyID: CompanyID,
          StatusArr: [listStatus],
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name === '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  /* -------------------------------------------- */
  /*                    VARIABLE                  */
  /* ---------------------------------------=---- */

  const handleSelectAll = e => {
    let jTemp = postIDs
    if (e.target.checked) {
      filteredList.map(v => {
        jTemp.add(v?.ScheduledBillingID)
      })
    } else {
      jTemp.clear()
    }
    setPostIDs(new Set(jTemp))
  }

  const handleCheckBoxChange = (e: any, postID, index: number) => {
    let temp = postIDs
    if (!temp.has(postID)) {
      temp.add(postID)
    } else {
      temp.delete(postID)
    }
    setPostIDs(new Set(temp))
  }

  const GreyTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
  })(Tooltip)

  let listPostIDs: any = Array.from(postIDs || [])

  useEffect(() => {
    if (clickReject) {
      onSubmitReject()
    }
  }, [clickReject])

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  /* -------------------------------------------- */
  /*                    SUBMIT                    */
  /* -------------------------------------------- */

  const onSubmitApprove = () => {
    postARScheduledBilling({
      variables: {
        ScheduledBillingIDs: listPostIDs,
        DocDate: formatDashDate(new Date()?.toISOString()),
        ApprovalStatus: ApprovalStatus.Completed,
      },
      refetchQueries: [
        {
          query: GetArScheduledBillingbyStatusListingDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: [ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  const onSubmitReject = () => {
    rejectScheduledBilling({
      variables: {
        ScheduledBillingIDs: listPostIDs,
        ApprovalStatus: ApprovalStatus.Active,
      },
      refetchQueries: [
        {
          query: GetArScheduledBillingbyStatusListingDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: [ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  return (
    <>
      {postARScheduledBillingLoading && <Loading />}
      {rejectJournalProcessingLoading && <Loading />}
      {ScheduledBillingbyStatusLoading && <Loading />}
      <ContentWrapper style={{ marginTop: isDesktop ? '70px' : '-7px' }}>
        {listStatus === 'SUBMIT' && filteredList?.length !== 0 ? (
          <span className="mdDesc flex-space" style={{ paddingLeft: '7px' }}>
            <Checkbox
              onChange={handleSelectAll}
              color="primary"
              value={''}
              checked={postIDs?.size === filteredList?.length}
              style={{ marginLeft: '-5px', marginTop: '-5px' }}
              disabled={handlePermDisabled({
                companyID: CompanyID,
                permEnum:
                  AcctPermission.AccReceivableScheduledBillingApproveReject,
              })}
            />
            <span style={{ marginLeft: '9px' }}>Select All</span>
          </span>
        ) : null}

        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                fetchMore({
                  variables: {
                    CompanyID: CompanyID,
                    StatusArr: [listStatus],
                    take: 30,
                    skip: currentLength,
                    searchValue:
                      getSearch?.name === '' || getSearch?.name === undefined
                        ? undefined
                        : getSearch?.name,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                ScheduledBillingbyStatusLoading && (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {advancedFilterList(filteredList)?.map((el, index) => {
                return (
                  <CardExpansion
                    summary={
                      <ListItem key={index}>
                        {listStatus === 'SUBMIT' ? (
                          <FormControl
                            component="fieldset"
                            style={{ width: '16px' }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={el.ScheduledBillingID}
                                  onChange={e => {
                                    handleCheckBoxChange(
                                      e,
                                      el?.ScheduledBillingID,
                                      index
                                    )
                                  }}
                                  onClick={e => e.stopPropagation()}
                                  checked={postIDs?.has(el?.ScheduledBillingID)}
                                  color="primary"
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '-5px',
                                  }}
                                  disabled={handlePermDisabled({
                                    companyID: CompanyID,
                                    permEnum:
                                      AcctPermission.AccReceivableScheduledBillingApproveReject,
                                  })}
                                />
                              }
                              label=""
                              name="check"
                            />
                          </FormControl>
                        ) : null}

                        <ListItemText
                          style={{
                            marginLeft: listStatus === 'SUBMIT' ? '25px' : null,
                          }}
                          primary={
                            <>
                              <span className={`xsTitle flex-space`}>
                                {el?.DebtorAccount?.DebtorName}
                              </span>
                              <span
                                className="desc"
                                style={{
                                  whiteSpace: 'break-spaces',
                                  lineBreak: 'auto',
                                }}
                              ></span>
                              <span
                                className="desc"
                                style={{
                                  color: '#FF0000',
                                }}
                              >
                                {formatDate(el?.BillingDate)}
                              </span>
                            </>
                          }
                          secondary={
                            <>
                              <span
                                className="desc"
                                style={{
                                  whiteSpace: 'break-spaces',
                                  lineBreak: 'auto',
                                }}
                              >
                                <GreyTooltip
                                  onClick={e => e.stopPropagation()}
                                  disableFocusListener
                                  title={
                                    <React.Fragment>
                                      <Grid container wrap="nowrap" spacing={2}>
                                        <Grid item>
                                          <Avatar
                                            style={{
                                              width: '20px',
                                              height: '20px',
                                            }}
                                          >
                                            <PersonIcon
                                              style={{ fontSize: 'small' }}
                                            />
                                          </Avatar>
                                        </Grid>
                                      </Grid>
                                    </React.Fragment>
                                  }
                                  arrow
                                  enterTouchDelay={0}
                                >
                                  <div>
                                    <IconText
                                      icon={
                                        <img
                                          src={SubmitterLog}
                                          style={{
                                            width: '12px',
                                            marginRight: '3px',
                                          }}
                                        />
                                      }
                                      font="desc"
                                    ></IconText>
                                  </div>
                                </GreyTooltip>
                              </span>
                              <span className="desc flex-space">
                                {el?.Description}
                              </span>
                              <span
                                className={`xsTitle`}
                                style={{ color: 'orange' }}
                              >
                                {amtStr(el?.BillingAmt)}
                              </span>
                            </>
                          }
                        />
                      </ListItem>
                    }
                  >
                    {
                      <>
                        <ARScheduledBillingDetailContent
                          listEl={el}
                          listStatus={'SUBMIT'}
                          userList={userList}
                          mode={'expansion'}
                        />
                      </>
                    }
                  </CardExpansion>
                )
              })}
            </InfiniteScroll>
          )}
        </List>
        <div style={{ marginBottom: '100px' }}></div>
      </ContentWrapper>

      <SubmitterDialog
        data={menu2?.obj}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        moduleName={'Scheduled Billing'}
      />

      <RejectDialog
        data={filteredList}
        openRejectDialog={openRejectDialog}
        setOpenRejectDialog={setOpenRejectDialog}
        setRejectDialogInput={setRejectDialogInput}
        setIsReject={setIsReject}
      />

      {listStatus === 'SUBMIT' ? (
        <AccountFooter
          options={[
            {
              name: 'Reject',
              onClick: () => handleSubmit(obj => onSubmitReject())(),
              color: 'secondary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    companyID: CompanyID,
                    permEnum:
                      AcctPermission.AccReceivableScheduledBillingApproveReject,
                  }),
              },
            },
            {
              name: 'Approve',
              onClick: () => {
                onSubmitApprove()
              },
              color: 'primary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    companyID: CompanyID,
                    permEnum:
                      AcctPermission.AccReceivableScheduledBillingApproveReject,
                  }),
              },
            },
          ]}
        />
      ) : null}
    </>
  )
}
