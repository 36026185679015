import { useGetTaxSchemeWithAccountQuery } from '@account-root/procurement-react/src/generated/graphql'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { MenuItem, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { AccountClient } from 'AccountClient'
import BigNumber from 'bignumber.js'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { AccountFooter } from 'components/Footer/AccountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  ApprovalStatus,
  useCreateScheduledBillingMutation,
  useGetBillItemQuery,
  useGetDebtorAccountQuery,
  useGetDepartmentListingByItemIdLazyQuery,
  useGetSalesOrderByDebtorLazyQuery,
  useSubmitArScheduledBillingMutation,
  useUpdateArScheduledBillingMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'

interface ScheduledBillingProps {
  DebtorAccountID: string
  CompanyID: string
  SalesOrderID: string
  BillingDate: Date
  BillingAmt: number
  BillItemID: string
  TaxSchemeID: string
  CostCentreID: string
  Description: string
}

export const ARScheduledBillingForm = (props: any) => {
  let history = useHistory()
  let location = useLocation()
  const editData: any = location?.state
  const { formMode } = props
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { ScheduledBillingID }: any = useParams()
  const { menu } = useMenuOption()

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
  }

  /* ------------------------------------------- */
  /*                   STATE                     */
  /* ------------------------------------------- */

  const [mountDefVal, setMountDefVal] = useState(!!editData)
  const [unitPrice, setUnitPrice] = useState(editData?.BillingAmt ?? 0)
  const [openExitConf, setOpenExitConf] = useState(null)
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */

  const ScheduledBillingSchema = yup.object().shape({
    DebtorAccountID: CommonYupValidation.requireField(SystemMsgs.debtorName()),
    SalesOrderID: CommonYupValidation.requireField(SystemMsgs.salesOrder()),
    BillingDate: CommonYupValidation.requireField(SystemMsgs.billingDate()),
    BillingAmt: yup
      .number()
      .transform((originalValue, originalObject) => {
        if (originalValue?.length > 3) {
          return parseInt(originalObject.replace(/,/g, ''))
        } else {
          return parseFloat(originalObject)
        }
      })
      .min(0.0001, 'Billing Amount must be at least 0.0001')
      .required('Billing Amount is required'),
    BillItemID: CommonYupValidation.requireField(SystemMsgs.billingItem()),
    TaxSchemeID: CommonYupValidation.requireField(SystemMsgs.tax()),
    CostCentreID: CommonYupValidation.requireField(SystemMsgs.department()),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
  })

  const { handleSubmit, register, setValue, control, errors, watch } = useForm<
    ScheduledBillingProps
  >({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(ScheduledBillingSchema),
  })

  /* -------------------------------------------- */
  /*                    QUEARY                    */
  /* -------------------------------------------- */

  const [
    fetchDepartmentListingByBillItemID,
    {
      loading: DepartmentListingByItemIDLoading,
      data: { getDepartmentListingByItemID } = {
        getDepartmentListingByItemID: [],
      },
    },
  ] = useGetDepartmentListingByItemIdLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ getDepartmentListingByItemID }) => {
      if (
        getDepartmentListingByItemID?.length === 1 &&
        !!getDepartmentListingByItemID[0]?.IsDefault
      ) {
        setValue('CostCentreID', getDepartmentListingByItemID[0]?.CostCentreID)
      }
      if (!!editData?.CostCentreID && mountDefVal === true) {
        setValue('CostCentreID', editData?.CostCentreID)
      }
    },
  })

  const {
    loading: DebtorAccountLoading,
    data: { getDebtorAccount } = { getDebtorAccount: [] },
  } = useGetDebtorAccountQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      if (editData?.SalesOrderID) {
        fetchSalesOrder({
          variables: {
            DebtorAccountID: editData?.DebtorAccountID,
          },
        })
      }
    },
  })

  const [
    fetchSalesOrder,
    {
      loading: SalesOrderLoading,
      data: { getSalesOrderByDebtor } = {
        getSalesOrderByDebtor: [],
      },
    },
  ] = useGetSalesOrderByDebtorLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: taxSchemeLoading,
    data: { getTaxSchemeWithAccount } = {
      getTaxSchemeWithAccount: [],
    },
  } = useGetTaxSchemeWithAccountQuery({
    fetchPolicy: 'network-only',
    client: AccountClient,
    variables: { accountID: user?.accountID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: billItemLoading,
    data: { getBillItem } = { getBillItem: [] },
  } = useGetBillItemQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      if (editData?.BillItemID && mountDefVal === true) {
        fetchDepartmentListingByBillItemID({
          variables: { CompanyID, BillItemID: editData?.BillItemID },
        })
      }
    },
  })

  const [
    createDraftScheduledBilling,
    { loading: createScheduledBillingLoading },
  ] = useCreateScheduledBillingMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      history.push(`/account-receivable/${CompanyID}/scheduled-billing`)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setOpenSnackBar(true)
    },
  })

  const [
    updateARScheduledBilling,
    { loading: updateARScheduledBillingLoading },
  ] = useUpdateArScheduledBillingMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      history.push(`/account-receivable/${CompanyID}/scheduled-billing`)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setOpenSnackBar(true)
    },
  })

  const [
    submitARScheduledBilling,
    { loading: submitARScheduledBillingLoading },
  ] = useSubmitArScheduledBillingMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      history.push(`/account-receivable/${CompanyID}/scheduled-billing`)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setOpenSnackBar(true)
    },
  })

  /* -------------------------------------------- */
  /*                    VARIABLE                    */
  /* -------------------------------------------- */

  let taxAmt = 0
  const taxAmount = (Amount: number) => {
    let taxRate = getTaxSchemeWithAccount?.filter(
      tax => tax?.TaxSchemeID === watch('TaxSchemeID')
    )[0]?.LatestTax?.Rate

    if (!!Amount) {
      taxAmt = new BigNumber(taxRate)
        .dividedBy(100)
        .multipliedBy(unitPrice)
        .toNumber()
      return taxAmt
    }
  }

  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */

  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => onSubmitDraftAndUpdate(data, 'draft'))()
    },
    color: 'primary',
    props: { type: 'submit' },
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: () => {
      handleSubmit(data => onSubmit(data, 'submit'))()
    },
    color: 'primary',
    props: { type: 'submit' },
  }

  let footerOptions: any[] = [draftFooterOption, submitFooterOption]

  const handleUnitPriceChange = event => {
    setUnitPrice(event.floatValue)
    setValue('BillingAmt', event?.floatValue)
  }

  /* -------------------------------------------- */
  /*                   ON SUBMIT                  */
  /* -------------------------------------------- */

  const onSubmitDraftAndUpdate = (data, status) => {
    if (formMode === 'add') {
      createDraftScheduledBilling({
        variables: {
          input: {
            CompanyID: CompanyID,
            DebtorAccountID: data?.DebtorAccountID,
            SalesOrderID: data?.SalesOrderID,
            BillingDate: new Date(data?.BillingDate).toISOString(),
            BillingAmt: data?.BillingAmt,
            BillItemID: data?.BillItemID,
            TaxSchemeID: data?.TaxSchemeID,
            CostCentreID: data?.CostCentreID,
            Description: data?.Description,
            ApprovalStatus: ApprovalStatus.Active,
          },
        },
      })
    } else if (formMode === 'edit') {
      updateARScheduledBilling({
        variables: {
          ScheduledBillingID: ScheduledBillingID,
          input: {
            CompanyID: CompanyID,
            DebtorAccountID: data?.DebtorAccountID,
            SalesOrderID: data?.SalesOrderID,
            BillingDate: new Date(data?.BillingDate).toISOString(),
            BillingAmt: data?.BillingAmt,
            BillItemID: data?.BillItemID,
            TaxSchemeID: data?.TaxSchemeID,
            CostCentreID: data?.CostCentreID,
            Description: data?.Description,
          },
        },
      })
    }
  }

  const onSubmit = (data, status) => {
    if (formMode === 'add') {
      createDraftScheduledBilling({
        variables: {
          input: {
            CompanyID: CompanyID,
            DebtorAccountID: data?.DebtorAccountID,
            SalesOrderID: data?.SalesOrderID,
            BillingDate: new Date(data?.BillingDate).toISOString(),
            BillingAmt: data?.BillingAmt,
            BillItemID: data?.BillItemID,
            TaxSchemeID: data?.TaxSchemeID,
            CostCentreID: data?.CostCentreID,
            Description: data?.Description,
            ApprovalStatus: ApprovalStatus.Submit,
          },
        },
      })
    } else if (formMode === 'edit') {
      submitARScheduledBilling({
        variables: {
          ScheduledBillingID: ScheduledBillingID,
          input: {
            CompanyID: CompanyID,
            DebtorAccountID: data?.DebtorAccountID,
            SalesOrderID: data?.SalesOrderID,
            BillingDate: new Date(data?.BillingDate).toISOString(),
            BillingAmt: data?.BillingAmt,
            BillItemID: data?.BillItemID,
            TaxSchemeID: data?.TaxSchemeID,
            CostCentreID: data?.CostCentreID,
            Description: data?.Description,
          },
        },
      })
    }
  }

  return (
    <>
      {taxSchemeLoading && <Loading />}
      {SalesOrderLoading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      {createScheduledBillingLoading && <Loading />}
      {submitARScheduledBillingLoading && <Loading />}
      {updateARScheduledBillingLoading && <Loading />}
      {DepartmentListingByItemIDLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          history.push(`/account-receivable/${CompanyID}/scheduled-billing`)
        }}
        smTitle={'Accounts Receivable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Accounts Receivable' },
          { name: 'Scheduled Billing', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              formMode === 'add'
                ? 'New'
                : formMode === 'approve-reject'
                ? 'Approve/Reject'
                : 'Edit',
            current: true,
          },
        ]}
      />

      <ContentWrapper float footer>
        <CardContents>
          {!DebtorAccountLoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = getDebtorAccount?.filter(
                  coa => coa?.DebtorAccountID === editData?.DebtorAccountID
                )[0]
                return (
                  <Autocomplete
                    ref={register}
                    options={
                      getDebtorAccount?.sort((a, b) => {
                        return a?.DebtorName?.localeCompare(b?.DebtorName)
                      }) || []
                    }
                    getOptionLabel={option => {
                      return `${option?.DebtorName}`
                    }}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('DebtorAccountID', newValue?.DebtorAccountID)
                      fetchSalesOrder({
                        variables: {
                          DebtorAccountID: newValue?.DebtorAccountID ?? null,
                        },
                      })
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    renderOption={(props, option) => {
                      return (
                        <div>
                          <div>
                            <span className="xsTitle">{props?.DebtorName}</span>
                          </div>
                        </div>
                      )
                    }}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.DebtorAccountID?.message}
                            error={errors?.DebtorAccountID ? true : false}
                            label="Debtor Name"
                            style={{ width: '100%' }}
                            required
                            margin="normal"
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Debtor Name"
              name="DebtorAccountID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              required
              helperText={errors?.DebtorAccountID?.message}
              error={errors?.DebtorAccountID ? true : false}
              defaultValue={editData ? editData?.DebtorAccountID : ''}
            />
          )}
          {!SalesOrderLoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = getSalesOrderByDebtor?.filter(
                  coa => coa?.so_id === editData?.SalesOrderID
                )[0]
                return (
                  <Autocomplete
                    options={getSalesOrderByDebtor || []}
                    getOptionLabel={option => {
                      return `${option?.so_title ?? ''}`
                    }}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('SalesOrderID', newValue?.so_id)
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    renderOption={(props, option) => {
                      return (
                        <div>
                          <div>
                            <span className="xsTitle">
                              {`${props?.so_title ?? ''}`}
                            </span>
                          </div>
                        </div>
                      )
                    }}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.SalesOrderID?.message}
                            error={errors?.SalesOrderID ? true : false}
                            label="Sales Order"
                            style={{ width: '100%' }}
                            required
                            margin="normal"
                            defaultValue={defVal}
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Sales Order"
              name="SalesOrderID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              required
              helperText={errors?.SalesOrderID?.message}
              error={errors?.SalesOrderID ? true : false}
              defaultValue={
                !!watch('DebtorAccountID') ? editData?.SalesOrderID : undefined
              }
            />
          )}
          <Controller
            as={KeyboardDatePicker}
            name="BillingDate"
            required
            label="Schedule Billing Date"
            control={control}
            format="dd/MM/yyyy"
            margin="normal"
            allowKeyboardControl
            ref={register}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            showTodayButton
            className="left"
            value={new Date()}
            defaultValue={editData ? editData?.BillingDate : new Date()}
            helperText={errors?.BillingDate?.message}
            error={errors?.BillingDate ? true : false}
            onChange={(e: Date | null) => {}}
          />

          <Controller
            name="BillingAmt"
            control={control}
            defaultValue={unitPrice}
            rules={{ required: true }}
            render={({ value }) => (
              <NumberFormat
                {...value}
                value={unitPrice}
                onValueChange={e => handleUnitPriceChange(e)}
                customInput={TextField}
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                margin="normal"
                label="Billing Amount"
                id="standard-basic"
                className="right"
                required
                helperText={errors?.BillingAmt?.message}
                error={errors?.BillingAmt ? true : false}
              />
            )}
            helperText={errors?.BillingAmt?.message}
            error={errors?.BillingAmt ? true : false}
          />
          {!billItemLoading && (
            <Controller
              render={({ onChange, onBlur, value }) => {
                const defVal = getBillItem?.filter(
                  x => x?.BillItemID === editData?.BillItemID
                )[0]

                return (
                  <Autocomplete
                    options={
                      getBillItem?.sort((a, b) => {
                        return a.Name.localeCompare(b.Name)
                      }) || []
                    }
                    getOptionLabel={option =>
                      `${option?.Name} | ${option?.Description}`
                    }
                    fullWidth
                    onChange={(value, newValue: any, reason) => {
                      setValue('BillItemID', newValue?.BillItemID)
                      setMountDefVal(false)
                      fetchDepartmentListingByBillItemID({
                        variables: {
                          CompanyID,
                          BillItemID: newValue?.BillItemID,
                        },
                      })
                      if (newValue?.TaxSchemeID) {
                        register('TaxSchemeID')
                        setValue('TaxSchemeID', newValue?.TaxSchemeID)
                      }
                    }}
                    renderOption={(props, option) => {
                      return (
                        <div>
                          <div>
                            <span className="xsTitle">{props?.Name}</span>
                          </div>
                          <div className="desc">{props?.Description}</div>
                        </div>
                      )
                    }}
                    defaultValue={defVal}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            required
                            label="Billing Item"
                            variant="standard"
                            fullWidth
                            name="BillItemID"
                            helperText={errors?.BillItemID?.message}
                            error={errors?.BillItemID ? true : false}
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              name="BillItemID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="dense"
              ref={register}
              required
              defaultValue={editData ? editData?.BillItemID : ''}
              helperText={errors?.BillItemID?.message}
              error={errors?.BillItemID ? true : false}
            />
          )}
          <Controller
            render={({ onChange, onBlur, value }) => {
              return (
                <TextField
                  helperText={
                    (watch('TaxSchemeID') === '' ||
                      watch('TaxSchemeID') === undefined) &&
                    errors?.TaxSchemeID?.message
                  }
                  error={
                    (watch('TaxSchemeID') === '' ||
                      watch('TaxSchemeID') === undefined) &&
                    errors?.TaxSchemeID
                      ? true
                      : false
                  }
                  select
                  margin="normal"
                  label="Tax"
                  required={true}
                  value={value}
                  defaultValue={menu?.obj?.TaxSchemeID || ''}
                  SelectProps={
                    !!getTaxSchemeWithAccount?.filter(
                      x => x?.TaxSchemeID === watch('TaxSchemeID')
                    )[0]?.Code
                      ? {
                          renderValue: () =>
                            `${
                              getTaxSchemeWithAccount?.filter(
                                x => x?.TaxSchemeID === watch('TaxSchemeID')
                              )[0]?.Code
                            }`,
                        }
                      : null
                  }
                  onChange={e => {
                    if (!!onChange) onChange(e)
                    onChange(e)
                  }}
                >
                  {getTaxSchemeWithAccount
                    ?.filter(v => {
                      return v?.AccTaxClass === 'INPUT'
                    })
                    ?.sort((a, b) => {
                      return a?.Code.localeCompare(b?.Code)
                    })
                    ?.map((el, index) => (
                      <MenuItem value={el?.TaxSchemeID} key={index}>
                        {<span className="text-noflow">{el?.Code}</span>}
                      </MenuItem>
                    ))}
                </TextField>
              )
            }}
            select
            name={'TaxSchemeID'}
            margin="dense"
            control={control}
            ref={register}
            className={'left'}
            autoComplete="off"
            defaultValue={editData ? editData?.TaxSchemeID : ''}
          />

          <TextField
            name="TaxRate"
            label="Tax Rate"
            value={amtStr(
              getTaxSchemeWithAccount?.filter(
                x => x?.TaxSchemeID === watch('TaxSchemeID')
              )[0]?.LatestTax?.Rate
            )}
            InputProps={{
              style: { color: '#96938e', backgroundColor: 'white' },
            }}
            className="left"
            fullWidth
            aria-disabled
            margin="normal"
            ref={register}
            style={{ color: 'white' }}
            disabled
          />
          <TextField
            name="TaxAmount"
            label="Tax Amount"
            value={amtNumStr(taxAmount(Number(unitPrice))).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            )}
            aria-disabled
            className="right"
            margin="normal"
            ref={register}
            style={{ color: 'white' }}
            disabled
          />
          <Controller
            render={({ value, onChange }) => {
              const defVal = !!watch('CostCentreID')
                ? getDepartmentListingByItemID?.find(
                    x => x?.CostCentreID === watch('CostCentreID')
                  )
                : null
              return (
                <Autocomplete
                  options={getDepartmentListingByItemID || []}
                  getOptionLabel={option =>
                    option ? `${option?.Code} | ${option?.Name}` : ''
                  }
                  fullWidth
                  onChange={(value, newValue: any) => {
                    onChange(newValue?.CostCentreID)
                  }}
                  renderOption={(props, option) => {
                    return (
                      <div>
                        <div>
                          <span className="xsTitle">{props?.Code}</span>
                        </div>
                        <div className="desc">{props?.Name}</div>
                      </div>
                    )
                  }}
                  value={defVal}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.CostCentreID?.message}
                          error={errors?.CostCentreID ? true : false}
                          label="Department"
                          style={{ width: '100%' }}
                          margin="dense"
                          required
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Department"
            name="CostCentreID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.CostCentreID?.message}
            error={errors?.CostCentreID ? true : false}
            required
          />
          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            defaultValue={editData ? editData?.Description : ''}
            autoComplete="off"
            control={control}
            fullWidth
            margin="normal"
            ref={register}
            required
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
          />
        </CardContents>
      </ContentWrapper>

      {footerOptions?.length > 0 && (
        <AccountFooter options={[...footerOptions]} />
      )}

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account0receivable/${CompanyID}/scheduled-billing`)
        }}
      />
    </>
  )
}
