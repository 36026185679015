import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { FileUploadInput as DocUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { changeDateFormat } from '@ifca-root/react-component/src/helpers/Functions/dateFunction'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import { DropdownTextfield } from 'containers/ARAPModule/Components/DropdownTextField'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import Fuse from 'fuse.js'
import {
  AcctPermission,
  ApprovalStatus,
  DocumentListingDocument,
  GetApBatchPaymentbyStatusDocument,
  RecordStatus,
  useCreateApBatchPaymentWithAllocationMutation,
  useDocumentListingLazyQuery,
  useGetApAllocationDocumentLazyQuery,
  useGetBankAccountQuery,
  useGetCreditCardTypeQuery,
  useGetCreditorAccountCompanyAssignmentQuery,
  useGetDocNumTitleQuery,
  useGetPaymentMethodQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateApBatchPaymentWithAllocationMutation,
} from 'generated/graphql'
import { handleObjExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import {
  formatDashDate,
  formatDate,
  getDatePlusDay,
} from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'
import '../APBatchPayment.scss'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { getTotalAmt } from 'containers/ARAPModule/Helper/AmountCalculation'

interface APBatchPaymentProps {
  CompanyID: string
  CreditorAccountID: string
  BatchPaymentID: string
  DocDate: Date
  TrxDate: string
  // DocAmt: number
  RefNo: string
  Description: string
  Attachment: null
  debitAmt: number
  approval: string
  user: string
  BankAccountID: string
  BankName: string
  PaymentMethodID: string
  CreditCardID: string
  ChequeNo: string
  ChequeDate: Date
  uploadAttach: string
  Allocation: any[]
}

export const APBatchPaymentFormV2 = (props: any) => {
  const { formMode, type } = props
  let user = JSON.parse(localStorage.getItem('loggedInUser'))

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  /* -------------------------------------------- */
  /*               STATE & VARIABLES              */
  /* -------------------------------------------- */
  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any
  const { CompanyID, BatchPaymentID }: any = useParams()
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [PaymentValidation, setPaymentValidation] = useState(false)
  const [valuepicked, setValuePicked] = useState<String>(null)
  const [openExitConf, setOpenExitConf] = useState(null)
  const [val, setVal] = useState(false)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  const [allocatedObj, setAllocatedObj] = useState({})

  const [initDocs, setInitDocs] = useState([])
  useEffect(() => {
    if (editData?.Attachment) {
      setInitDocs(editData?.Attachment)
    }
  }, [editData])

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))

  /* -------------------------------------------- */
  /*               QUERY & MUTATION               */
  /* -------------------------------------------- */
  //Creditor Account Company Assignment//
  const {
    loading: CreditorAccountCompanyAssignmentLoading,
    error: CreditorAccountCompanyAssignmentError,
    data: { getCreditorAccountCompanyAssignment } = {
      getCreditorAccountCompanyAssignment: [],
    },
  } = useGetCreditorAccountCompanyAssignmentQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  })

  const {
    loading: PaymentMethodLoading,
    error: PaymentMethodError,
    data: { getPaymentMethod } = { getPaymentMethod: [] },
  } = useGetPaymentMethodQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {},
  })

  const {
    called: bankAccountCalled,
    loading: bankAccountLoading,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RecordStatus: RecordStatus?.Active,
    },
  })

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RefTable: 'AP_Payment',
    },
  })

  const {
    loading: CreditCardTypeLoading,
    error: CreditCardTypeError,
    data: { getCreditCardType } = { getCreditCardType: [] },
  } = useGetCreditCardTypeQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: {},
  })

  const [
    createAPBatchPaymentWithAllocation,
    {
      loading: createAPBatchPaymentLoading,
      called: createAPBatchPaymentWithAllocationCalled,
      error: createAPBatchPaymentWithAllocationError,
    },
  ] = useCreateApBatchPaymentWithAllocationMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: ({ createAPBatchPaymentWithAllocation }) => {
      if (createAPBatchPaymentWithAllocation) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.createNewRecord())
        setTimeout(() => {
          history.push({
            pathname: `/account-payable/${CompanyID}/batch-payment`,
            state: { success: true, msgMode: 'create' },
          })
        }, 500)
      } else {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.createNewRecordFail())
      }
    },
  })

  const [
    updateAPBatchPaymentWithAllocation,
    {
      loading: updateAPBatchPaymentLoading,
      called: updateAPBatchPaymentWithAllocationCalled,
      error: updateAPBatchPaymentWithAllocationError,
    },
  ] = useUpdateApBatchPaymentWithAllocationMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: ({ updateAPBatchPaymentWithAllocation }) => {
      if (updateAPBatchPaymentWithAllocation) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.updateRecord())
        setTimeout(() => {
          history.push({
            pathname: `/account-payable/${CompanyID}/batch-payment`,
            state: { success: true, msgMode: 'update' },
          })
        }, 500)
      } else {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.updateRecordFail())
      }
    },
  })

  const [
    loadAllocationDocument,
    {
      loading: allocationDocumentLoading,
      called: getAllocationDocumentCalled,
      error: getAllocationDocumentError,
      data: { getAllocationDocument } = { getAllocationDocument: [] },
    },
  ] = useGetApAllocationDocumentLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: ({ getAllocationDocument }) => {
      let arr = []
      if (getAllocationDocument?.length > 0) {
        getAllocationDocument?.map((alloc, index) => {
          const creditorDebtorAccKey =
            alloc?.CreditRefTable?.includes('AP_') ||
            alloc?.DebitRefTable?.includes('AP_')
              ? 'CreditorAccountID'
              : 'DebtorAccountID'
          arr.push({
            AllocationID: alloc?.AllocationID,
            IsChecked: alloc?.AllocationID ? true : false,
            [alloc.CreditOrDebit]: alloc[`${alloc?.CreditOrDebit}ID`],
            CompanyName: alloc?.CompanyName,
            CreditOrDebit: alloc?.CreditOrDebit,
            [`${creditorDebtorAccKey}`]: alloc?.[`${creditorDebtorAccKey}`],
            DocDate: alloc?.DocDate,
            DocNo: alloc?.DocNo,
            Description: alloc?.Description,
            // for previous allocated amt on the same doc
            AllocationAmt: Number(
              allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0
            ),
            // balance amount + previous allocated amt on the same doc
            BalanceAmt: getTotalAmt(
              true,
              alloc?.BalanceAmt,
              allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0,
              0
            ),

            // credit or debit id
            [`${alloc?.CreditOrDebit}ID`]: alloc[`${alloc?.CreditOrDebit}ID`],
            // credit or debit ref table
            [`${alloc?.CreditOrDebit}RefTable`]: alloc[
              `${alloc?.CreditOrDebit}RefTable`
            ],
            // credit or debit doc no
            [`${alloc?.CreditOrDebit}DocNo`]: alloc[
              `${alloc?.CreditOrDebit}DocNo`
            ],
          })

          register(`Allocation[${alloc?.CreditID}].AllocationID`)
          register(`Allocation[${alloc?.CreditID}].CurrentIndex`)
          register(`Allocation[${alloc?.CreditID}].IsChecked`)
          register(`Allocation[${alloc?.CreditID}].CompanyName`)
          register(`Allocation[${alloc?.CreditID}].CreditOrDebit`)
          register(`Allocation[${alloc?.CreditID}].${creditorDebtorAccKey}`)
          register(`Allocation[${alloc?.CreditID}].DocDate`)
          register(`Allocation[${alloc?.CreditID}].DocNo`)
          register(`Allocation[${alloc?.CreditID}].Description`)
          register(`Allocation[${alloc?.CreditID}].AllocationAmt`)
          register(`Allocation[${alloc?.CreditID}].BalanceAmt`)
          register(`Allocation[${alloc?.CreditID}].${alloc?.CreditOrDebit}ID`)
          register(
            `Allocation[${alloc?.CreditID}].${alloc?.CreditOrDebit}RefTable`
          )
          register(
            `Allocation[${alloc?.CreditID}].${alloc?.CreditOrDebit}DocNo`
          )

          setValue(
            `Allocation[${alloc?.CreditID}].AllocationID`,
            alloc[`AllocationID`]
          )
          setValue(`Allocation[${alloc?.CreditID}].CurrentIndex`, index)

          setValue(
            `Allocation[${alloc?.CreditID}].IsChecked`,
            alloc[`AllocationID`] ? true : false
          )
          setValue(
            `Allocation[${alloc?.CreditID}].CompanyName`,
            alloc?.CompanyName
          )
          setValue(
            `Allocation[${alloc?.CreditID}].CreditOrDebit`,
            alloc?.CreditOrDebit
          )
          setValue(
            `Allocation[${alloc?.CreditID}].${creditorDebtorAccKey}`,
            alloc?.[`${creditorDebtorAccKey}`]
          )
          setValue(`Allocation[${alloc?.CreditID}].DocDate`, alloc?.DocDate)
          setValue(`Allocation[${alloc?.CreditID}].DocNo`, alloc?.DocNo)
          setValue(
            `Allocation[${alloc?.CreditID}].Description`,
            alloc?.Description
          )
          setValue(
            `Allocation[${alloc?.CreditID}].AllocationAmt`,
            Number(allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0)
          )
          setValue(
            `Allocation[${alloc?.CreditID}].BalanceAmt`,
            Number(alloc?.BalanceAmt ?? 0)
          )
          setValue(
            `Allocation[${alloc?.CreditID}].${alloc?.CreditOrDebit}ID`,
            alloc[`${alloc?.CreditOrDebit}ID`]
          )
          setValue(
            `Allocation[${alloc?.CreditID}].${alloc?.CreditOrDebit}RefTable`,
            alloc[`${alloc?.CreditOrDebit}RefTable`]
          )
          setValue(
            `Allocation[${alloc?.CreditID}].${alloc?.CreditOrDebit}DocNo`,
            alloc[`${alloc?.CreditOrDebit}DocNo`]
          )

          return alloc
        })
        append(arr)
      }
    },
  })

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  /* -------------------------------------------- */
  /*                    USEFORM                   */
  /* -------------------------------------------- */
  const APBatchPaymentFormSchema = yup.object().shape({
    DocDate: yup.string().required('Doc Date is Required'),
    TrxDate: yup.string().required('Transaction Date is Required'),
    RefNo: CommonYupValidation.requireField(SystemMsgs.referenceNo()),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    // BatchPaymentID: CommonYupValidation.requireField(SystemMsgs.journalType()),
    PaymentMethodID: CommonYupValidation.requireField(
      'Payment Method is required'
    ),
    CreditCardID:
      PaymentValidation && valuepicked?.includes('Card')
        ? CommonYupValidation.requireField('Credit Card Type is required')
        : null,
    ChequeNo: yup.string().when('PaymentMethodID', {
      is: getPaymentMethod?.find(method => method?.Name === 'Cheque')
        ?.PaymentMethodID,
      then: yup.string().required('Cheque no. is required'),
      otherwise: null,
    }),
    ChequeDate: yup.date().when('PaymentMethodID', {
      is: getPaymentMethod?.find(method => method?.Name === 'Cheque')
        ?.PaymentMethodID,
      then: yup.date().required('Cheque date is required'),
      otherwise: null,
    }),

    BankAccountID: CommonYupValidation.requireField('Bank Account is required'),
    // Allocation: yup.array().of(
    //   yup.object().shape({
    //     AllocationAmt: CommonYupValidation?.requireField(
    //       'Allocation Amt is required'
    //     ).nullable(),
    //   })
    // ),
    // TaxRate: yup.string().required('Tax Rate is Required'),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    getValues,
    clearErrors,
    setError,
  } = useForm<APBatchPaymentProps>({
    defaultValues: {
      CompanyID: editData ? editData?.CompanyID : '',
      DocDate: editData ? editData?.DocDate : new Date(),
      TrxDate: editData ? editData?.TransactionDate : new Date(),
      RefNo: editData ? editData?.RefNo : '',
      CreditorAccountID: editData ? editData?.CreditAccountID : '',
      BankAccountID: editData ? editData?.BankAccountID : '',
      PaymentMethodID: editData ? editData?.PaymentMethodID : '',
      CreditCardID: editData ? editData?.CreditCardID : '',
      ChequeNo: editData ? editData?.ChequeNo : '',
      ChequeDate: editData ? editData?.ChequeDate : new Date(),
      // DocAmt: editData ? editData?.DocAmt : 0,
      Description: editData ? editData?.Description : '',
      Attachment: editData ? editData?.Attachment : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(APBatchPaymentFormSchema),
  })

  let statusInput = mode => {
    let temp
    switch (mode) {
      case 'submit':
        temp = ApprovalStatus.Submit
        break
      case 'draft':
        temp = ApprovalStatus.Active
        break
      case 'approve':
        temp = ApprovalStatus.Approved
        break
      case 'reject':
        temp = ApprovalStatus.Rejected
        break
    }
    return temp
  }

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'Allocation',
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  const [selectedBank, setSelectedBank] = useState(null)
  // select bank
  const handleBankChange = event => {
    setSelectedBank(
      getBankAccount.find(x => x?.BankAccountID === event?.target?.value)
    )
  }

  const handlePMChange = event => {
    if (
      getPaymentMethod?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name === 'Cheque' ||
      getPaymentMethod
        ?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name?.includes('Card')
    ) {
      setPaymentValidation(true)
    }
    if (
      getPaymentMethod?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name === 'Cheque'
    ) {
      setValuePicked('Cheque')
    } else if (
      getPaymentMethod
        ?.find(el => el?.PaymentMethodID === event?.target?.value)
        ?.Name?.includes('Card')
    ) {
      setValuePicked('Card')
    }
  }

  //Changing If PM chosed CreditCard
  const [selectedCC, setSelectedCC] = useState(null)
  const creditcard: any = getCreditCardType

  const handleCCChange = event => {
    setSelectedCC(
      creditcard.find(x => x?.CreditCardID === event?.target?.value)
    )
  }

  const getTotalAmount = () => {
    const allocationData = Object.values(watch('Allocation') || {}) // Assuming 'watch' is a function to get form field values
    if (allocationData && allocationData.length > 0) {
      return allocationData.reduce((prevValue, currValue) => {
        const allocationAmt = Number(currValue?.AllocationAmt) || 0 // Use 0 if AllocationAmt is undefined
        return prevValue + allocationAmt
      }, 0)
    } else {
      return 0
    }
  }

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  /* -------------------------------------------- */
  /*                   USEEFFECT                  */
  /* -------------------------------------------- */
  useEffect(() => {
    if (editData?.Allocation) {
      let allocatedArr = editData?.Allocation
      allocatedArr?.map(allocated => {
        if (!(allocated[`${allocated?.CreditOrDebit}ID`] in allocatedObj)) {
          allocatedObj[allocated[`${allocated?.CreditOrDebit}ID`]] =
            allocated?.AllocationAmt
        }
      })
    }
  }, [editData])

  useEffect(() => {
    loadAllocationDocument({
      variables: {
        ...(BatchPaymentID
          ? {
              entityID: editData?.APPayment?.map(payment => payment?.PaymentID),
            }
          : null),
        companyID: CompanyID,
        refTable: 'AP_Payment',
      },
    })
  }, [editData])

  // Edit Bank Account
  useEffect(() => {
    if (!!editData && !!getBankAccount) {
      setSelectedBank(
        getBankAccount?.find(e => e?.BankAccountID === editData?.BankAccountID)
      )
    }
  }, [editData, getBankAccount])

  useEffect(() => {
    if (fields && fields?.length > 0) {
      setOriginalListing(fields, v => {
        v['docDateDateFormatted'] = formatDate(v.DocDate)
      })
    }
  }, [fields])

  useEffect(() => {
    if (
      formMode === 'edit' ||
      formMode === 'Approve-reject' ||
      formMode === 'Resubmit'
    ) {
    }
  }, [formMode, editData])

  useEffect(() => {
    if (getSearch?.name && filteredList) {
      const keys = [
        'docDateDateFormatted',
        'Description',
        'CompanyName',
        'CreditDocNo',
        'BalanceAmt',
      ]
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(getSearch?.name)
      const val = result?.map(x => x.item)
      handleSearch(getSearch?.name + '', keys)
    }
  }, [getSearch?.name, originalList])

  /* -------------------------------------------- */
  /*                  ATTACHMENT                  */
  /* -------------------------------------------- */
  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    loadDoc,
    { loading: DocLoad, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
  })

  useEffect(() => {
    if (!DocLoad && formMode !== 'add') {
      loadDoc({ variables: { refID: BatchPaymentID } })
    }
    if (editData?.files) {
      setFiles(editData?.files)
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [formMode, BatchPaymentID, editData])

  const onSubmit = (data, status) => {
    if (val == false) {
      setVal(true)
      if (formMode === 'add') {
        createAPBatchPaymentWithAllocation({
          variables: {
            input: {
              CompanyID: CompanyID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              RefNo: data?.RefNo,
              PaymentMethodID: data?.PaymentMethodID,
              CreditCardID: data?.CreditCardID,
              ChequeNo: data?.ChequeNo,
              ChequeDate: data?.ChequeDate,
              ChequeExpiryDate: !!data?.ChequeDate
                ? changeDateFormat(
                    getDatePlusDay(data?.ChequeDate, 180),
                    'DD MMM YYYY'
                  )
                : null,
              BankAccountID: data?.BankAccountID,
              Description: data?.Description,
              ApprovalStatus: statusInput(status),
              Attachment: files,
            },
            allocationInput: Object.values(watch('Allocation'))
              ?.filter(
                alloc =>
                  Number(alloc?.AllocationAmt) !== 0 || alloc?.AllocationID
              )
              ?.map(alloc => {
                const mainCreditOrDebit =
                  alloc?.CreditOrDebit === 'Debit' ? 'Credit' : 'Debit'
                const creditorDebtorAccKey =
                  alloc?.CreditRefTable?.includes('AP_') ||
                  alloc?.DebitRefTable?.includes('AP_')
                    ? 'CreditorAccountID'
                    : 'DebtorAccountID'
                return {
                  ...(alloc?.AllocationID
                    ? { AllocationID: alloc?.AllocationID }
                    : null),
                  AllocationAmt: Number(alloc?.AllocationAmt),
                  CreditOrDebit: alloc?.CreditOrDebit,
                  [`${creditorDebtorAccKey}`]: alloc?.[
                    `${creditorDebtorAccKey}`
                  ],
                  CompanyID: CompanyID,
                  // for allocated table
                  [`${alloc?.CreditOrDebit}RefTable`]: alloc?.[
                    `${alloc?.CreditOrDebit}RefTable`
                  ],
                  [`${alloc?.CreditOrDebit}ID`]: alloc?.[
                    `${alloc?.CreditOrDebit}ID`
                  ],
                  // main table
                  [`${mainCreditOrDebit}RefTable`]: 'AP_Payment',
                }
              }),
          },
          refetchQueries: [
            {
              query: GetApBatchPaymentbyStatusDocument,
              variables: {
                CompanyID: CompanyID,
                StatusArr: ['ACTIVE'],
              },
            },
            {
              query: DocumentListingDocument,
              variables: {
                refTable: 'S_Attachment',
                refID: editData?.BatchPaymentID,
              },
            },
          ],
        })
        // }
      } else if (formMode !== 'add') {
        updateAPBatchPaymentWithAllocation({
          variables: {
            input: {
              CompanyID: CompanyID,
              BatchPaymentID: BatchPaymentID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              RefNo: data?.RefNo,
              PaymentMethodID: data?.PaymentMethodID,
              CreditCardID: data?.CreditCardID,
              ChequeNo: data?.ChequeNo,
              ChequeDate: data?.ChequeDate,
              ChequeExpiryDate: !!data?.ChequeDate
                ? changeDateFormat(
                    getDatePlusDay(data?.ChequeDate, 180),
                    'DD MMM YYYY'
                  )
                : null,
              BankAccountID: data?.BankAccountID,
              Description: data?.Description,
              ApprovalStatus: statusInput(status),
              Attachment: files,
            },
            allocationInput: Object.values(watch('Allocation'))
              ?.filter(
                alloc =>
                  Number(alloc?.AllocationAmt) !== 0 || alloc?.AllocationID
              )
              ?.map(alloc => {
                const mainCreditOrDebit =
                  alloc?.CreditOrDebit === 'Debit' ? 'Credit' : 'Debit'
                const creditorDebtorAccKey =
                  alloc?.CreditRefTable?.includes('AP_') ||
                  alloc?.DebitRefTable?.includes('AP_')
                    ? 'CreditorAccountID'
                    : 'DebtorAccountID'

                return {
                  ...(alloc?.AllocationID
                    ? { AllocationID: alloc?.AllocationID }
                    : null),
                  AllocationAmt: Number(alloc?.AllocationAmt)
                    ? Number(alloc?.AllocationAmt)
                    : 0,
                  CreditOrDebit: alloc?.CreditOrDebit,
                  [`${creditorDebtorAccKey}`]: alloc?.[
                    `${creditorDebtorAccKey}`
                  ],
                  CompanyID: CompanyID,
                  // for allocated table
                  [`${alloc?.CreditOrDebit}RefTable`]: alloc?.[
                    `${alloc?.CreditOrDebit}RefTable`
                  ],
                  [`${alloc?.CreditOrDebit}ID`]: alloc?.[
                    `${alloc?.CreditOrDebit}ID`
                  ],
                  // main table
                  [`${mainCreditOrDebit}RefTable`]: 'AP_Payment',
                }
              }),
          },
          refetchQueries: [
            {
              query: GetApBatchPaymentbyStatusDocument,
              variables: {
                CompanyID: CompanyID,
                StatusArr: ['ACTIVE'],
              },
            },
            {
              query: DocumentListingDocument,
              variables: {
                refTable: 'S_Attachment',
                refID: editData?.BatchPaymentID,
              },
            },
          ],
        })
      }
    }
  }

  const createUpdateCalled = editData
    ? updateAPBatchPaymentWithAllocationCalled
    : createAPBatchPaymentWithAllocationCalled

  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */
  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: { type: 'submit' },
    disabled:
      Object.values(watch('Allocation') || {})?.filter(
        alloc => alloc?.IsChecked
      )?.length === 0 ||
      handlePermDisabled({
        companyID: CompanyID,
        permEnum: AcctPermission.AccPayableBatchPaymentDraft,
      }),
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
    },
    // onClick: e => {
    //   handleSubmit(data =>
    //     getDocumentNumberHeader?.filter(x => x?.RefTable === 'AP_Payment')
    //       ?.length === 0
    //       ? (setOpenSnackBar(true),
    //         setSnackBarMsg(SystemMsgs.errorNumberingStructure()))
    //       : !createUpdateCalled && onSubmit(data, 'submit')
    //   )()
    // },
    color: 'primary',
    props: { type: 'submit' },
    disabled:
      Object.values(watch('Allocation') || {})?.filter(
        alloc => alloc?.IsChecked
      )?.length === 0 ||
      handlePermDisabled({
        companyID: CompanyID,
        permEnum: AcctPermission.AccPayableBatchPaymentUpdate,
      }),
  }

  const footerOptions: any[] = [draftFooterOption, submitFooterOption]

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleObjExitConfirmation({
      watch: watch,
      editData: editData,
      formMode: formMode,
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
      hasAllocation: true,
    })

  const [selectedItems, setSelectedItems] = useState([])

  const SelectAll = (checked: boolean) => {
    const allocationArray = Object.values(watch('Allocation') || {})
    const updatedAllocationArray = allocationArray.map(allocationItem => {
      // Create a copy of the allocation item with updated IsChecked value
      const updatedAllocationItem = { ...allocationItem }
      setValue(`Allocation[${allocationItem?.CreditID}].IsChecked`, checked) // Set IsChecked to the provided value
      if (checked) {
        // If checked, also update AllocationAmt if necessary
        updatedAllocationItem.AllocationAmt =
          allocatedObj[allocationItem[`${allocationItem.CreditOrDebit}ID`]] ||
          allocationItem.BalanceAmt
        // Add the item's ID to selectedItems if checked
        setSelectedItems(prevState => [...prevState, allocationItem.CreditID])
      } else {
        // If unchecked, set AllocationAmt to 0
        updatedAllocationItem.AllocationAmt = 0
        // Remove the item's ID from selectedItems if unchecked
        setSelectedItems(prevState =>
          prevState.filter(id => id !== allocationItem.CreditID)
        )
      }
      return updatedAllocationItem
    })

    // Set the updated Allocation array in the form
    setValue('Allocation', updatedAllocationArray)
  }

  return (
    <>
      {bankAccountLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {PaymentMethodLoading && <Loading />}
      {CreditCardTypeLoading && <Loading />}
      {allocationDocumentLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}
      {createAPBatchPaymentLoading && <Loading />}
      {updateAPBatchPaymentLoading && <Loading />}
      {CreditorAccountCompanyAssignmentLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          hasChanges() === true
            ? setOpenExitConf(true)
            : history.push(`/account-payable/${CompanyID}/batch-payment`)
          localStorage.removeItem('DebitAmt')
          localStorage.removeItem('searchFilter')
        }}
        smTitle={'Accounts Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Accounts Payable' },
          { name: 'AP Submenu' },
          { name: 'Batch Payment', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              mode === 'add'
                ? 'New'
                : mode === 'approve-reject'
                ? 'Approve/Reject'
                : 'Edit',
            current: true,
          },
        ]}
      />

      <ContentWrapper float footer>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocDate"
              label="Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch(formMode === 'add' ? new Date() : editData?.DocDate)}
              margin="dense"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData ? editData?.DocDate : new Date()}
              showTodayButton
              disabled={mode == 'approve-reject'}
              style={{
                paddingTop: '10px',
              }}
              className=" left"
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxDate"
              required
              label="Transaction Date"
              control={control}
              format="dd/MM/yyyy"
              margin="normal"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              showTodayButton
              className="right"
              value={new Date()}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            required
            autoComplete="off"
            control={control}
            margin="dense"
            ref={register}
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            defaultValue={editData ? editData?.RefNo : ''}
            disabled={mode == 'approve-reject'}
          />

          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Payment Method"
                required
                select
                value={value}
                margin="dense"
                onChange={e => {
                  onChange()
                  handlePMChange(e)
                  setValue('PaymentMethodID', e?.target?.value)
                }}
                defaultValue={editData ? editData?.PaymentMethodID : ''}
                disabled={formMode === 'approve-reject'}
                helperText={errors?.PaymentMethodID?.message}
                error={errors?.PaymentMethodID ? true : false}
              >
                {getPaymentMethod?.map((el, index) => (
                  <MenuItem key={index} value={el?.PaymentMethodID}>
                    {el?.Name}
                  </MenuItem>
                ))}
              </TextField>
            )}
            control={control}
            helperText={errors?.PaymentMethodID?.message}
            error={errors?.PaymentMethodID ? true : false}
            onChange={e => {
              setValue('PaymentMethodID', e?.target?.value)
            }}
            ref={register()}
            name="PaymentMethodID"
            autoComplete="off"
            value={editData?.PaymentMethodID}
            margin="dense"
            multiline={true}
            fullWidth
            required
          />

          {getPaymentMethod
            .find(el => el.PaymentMethodID === watch('PaymentMethodID'))
            ?.Name?.includes('Card') && (
            <Controller
              render={({ onChange, value }) => (
                <TextField
                  select
                  required
                  name="CreditCardID"
                  margin="dense"
                  label="Card Type"
                  onChange={e => {
                    onChange(e)
                    handleCCChange(e)
                  }}
                  defaultValue={editData ? editData?.CreditCardID : ''}
                  disabled={mode == 'approve-reject'}
                >
                  {getCreditCardType
                    ?.sort((a, b) => {
                      return a?.Name?.localeCompare(b?.Name)
                    })
                    .map((el, index) => (
                      <MenuItem key={index} value={el?.CreditCardID}>
                        {`${el?.Name}`}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              label="Card Type"
              name="CreditCardID"
              autoComplete="off"
              control={control}
              helperText={errors?.CreditCardID?.message}
              error={errors?.CreditCardID ? true : false}
              multiline={true}
              defaultValue={editData?.CreditCardID}
              fullWidth
              margin="dense"
              ref={register}
            />
          )}

          {getPaymentMethod.find(
            el => el?.PaymentMethodID === watch('PaymentMethodID')
          )?.Name === 'Cheque' && (
            <>
              <Controller
                as={TextField}
                id="standard-basic"
                name="ChequeNo"
                label="Cheque No"
                autoComplete="off"
                control={control}
                margin="dense"
                className="left"
                required
                ref={register}
                defaultValue={editData ? editData?.ChequeNo : ''}
                disabled={mode == 'approve-reject'}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  required
                  as={KeyboardDatePicker}
                  name="ChequeDate"
                  label="Cheque Date"
                  control={control}
                  onChange={(date: Date | null) => {}}
                  format="dd MMM yyyy"
                  value={watch('ChequeDate')}
                  margin="dense"
                  allowKeyboardControl
                  ref={register}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  defaultValue={
                    formMode === 'add' ? new Date() : editData?.ChequeDate
                  }
                  showTodayButton
                  className="right"
                  disabled={mode == 'approve-reject'}
                />
              </MuiPickersUtilsProvider>
            </>
          )}

          <DropdownTextfield
            disabled={formMode === 'approve-reject' ? true : false}
            helperText={errors?.BankAccountID?.message}
            error={errors?.BankAccountID ? true : false}
            label="Company Bank Account No"
            handleChangeEnabled
            handleChange={handleBankChange}
            selectProps={{
              renderValue: () =>
                `${selectedBank?.AccountNo} (${selectedBank?.BankProfile?.Name} | ${selectedBank?.Code})`,
            }}
            customDropdown={getBankAccount?.map((el, index) => (
              <MenuItem
                id="contract-select"
                key={index}
                value={el?.BankAccountID}
              >
                <div className="content-wrap full">
                  <span className="xsTitle">
                    {el?.BankProfile?.Name} ({el?.BankProfile?.Address?.city})
                  </span>
                  <div className="desc">
                    Account No: {`${el?.AccountNo} (${el?.Code})`}
                  </div>
                </div>
              </MenuItem>
            ))}
            name="BankAccountID"
            control={control}
            register={register}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            ref={register}
          />

          <DocUploadInput
            placeholder={previewFiles.length === 0 ? 'Attachment' : null}
            label={previewFiles.length > 0 ? 'Attachment' : null}
            files={files}
            onHandleUploadChange={handleUploadChange}
            multiple
            accept={
              'application/msword, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            }
            mapData={'application'}
            imagePreview={
              <>
                {previewFiles?.map((v, i) => (
                  <UploadPreview
                    remove
                    key={v}
                    src={v}
                    onClick={() => removeFile(i)}
                    mediaType={
                      files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                    }
                  />
                ))}
              </>
            }
            disabled={mode === 'approve-reject'}
            style={{ marginTop: '15px' }}
          />
        </CardContents>

        <div className="search-filter ">
          <SearchBar
            borderRadius="4px"
            onChangeAction={e => {
              handleSearch(e?.target?.value, [
                'docDateDateFormatted',
                'Description',
                'CompanyName',
                'DocNo',
                'BalanceAmt',
              ])
              localStorage.setItem(
                'searchFilter',
                JSON.stringify({
                  name: e?.target?.value,
                })
              )
            }}
            defaultValue={getSearch?.name ? getSearch?.name : ''}
            onCloseAction={() => {
              handleSearch('', [])
            }}
            isDefaultValue={!!getSearch}
          />
        </div>

        {filteredList?.length > 0 ? (
          <>
            <span className="mdDesc flex-space" style={{ paddingLeft: '7px' }}>
              <Checkbox
                onChange={e => {
                  SelectAll(e.target.checked)
                }}
                color="primary"
                style={{ marginLeft: '-5px' }}
                checked={
                  Object.values(watch('Allocation') || {})?.every(
                    allocItem => allocItem.IsChecked
                  ) || false
                }
              />
              Select All
            </span>

            <CardContents>
              <div className="table-wrap rm-padding">
                {filteredList?.map((alloc, index) => {
                  const allocation = `Allocation[${alloc?.CreditID}]`
                  let balanceAmt
                  balanceAmt =
                    alloc?.AllocationID &&
                    fields[index]?.AllocationAmt > Number(alloc?.BalanceAmt)
                      ? fields[index]?.AllocationAmt + Number(alloc?.BalanceAmt)
                      : Number(alloc?.BalanceAmt)

                  return (
                    <>
                      <Grid
                        container
                        className="table-content"
                        key={alloc?.id}
                        style={{ padding: '12px 12px 4px 4px' }}
                      >
                        <Grid item xs={1} style={{ placeSelf: 'start' }}>
                          <FormControl
                            component="fieldset"
                            style={{ width: '16px' }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={e => {
                                    const obj = `Allocation[${alloc?.CreditID}]`

                                    if (e?.target?.checked) {
                                      setValue(`${obj}.IsChecked`, true)
                                      setValue(
                                        `${obj}.AllocationAmt`,
                                        allocatedObj[
                                          alloc[`${alloc?.CreditOrDebit}ID`]
                                        ] ?? alloc?.BalanceAmt
                                      )
                                    } else {
                                      setValue(`${obj}.IsChecked`, false)
                                      setValue(`${obj}.AllocationAmt`, 0)
                                    }
                                  }}
                                  checked={watch(`${allocation}.IsChecked`)}
                                  color="primary"
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '-5px',
                                  }}
                                />
                              }
                              label=""
                              name="check"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={7} style={{ placeSelf: 'start' }}>
                          <div
                            className="desc text-noflow"
                            style={{
                              lineBreak: 'anywhere',
                              lineHeight: '20px',
                              paddingLeft: '8px',
                            }}
                          >
                            <div>
                              <span className="mdDesc flex-space">
                                {formatDate(alloc?.DocDate)}
                              </span>
                              <span
                                className="mdDesc "
                                style={{ marginLeft: '15px', fontWeight: 700 }}
                              >
                                {alloc?.[`${alloc?.CreditOrDebit}DocNo`]}
                              </span>
                            </div>
                            <div>
                              <span
                                className="xxTitle flex-space"
                                style={{
                                  whiteSpace: 'initial',
                                  display: 'inline-block',
                                }}
                              >
                                {alloc?.CompanyName}
                              </span>
                            </div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          style={{
                            placeSelf: 'start',
                            marginRight: 'auto',
                            textAlign: 'right',
                            flexWrap: 'wrap',
                            display: 'flex',
                            justifyContent: 'end',
                          }}
                        >
                          <div
                            className="desc text-noflow"
                            style={{
                              lineBreak: 'anywhere',
                              lineHeight: '20px',
                            }}
                          >
                            <span
                              className="mdDesc"
                              style={{ fontWeight: 700 }}
                            >
                              {`O/S: ${amtStr(balanceAmt)}`}
                            </span>

                            {watch(`${allocation}.IsChecked`) && (
                              <div>
                                <span
                                  className="mdDesc alloc-amt-input"
                                  style={{ height: '10px' }}
                                >
                                  <Controller
                                    ref={register}
                                    name={`${allocation}.AllocationAmt`}
                                    control={control}
                                    required
                                    defaultValue={
                                      alloc?.AllocationAmt !== 0 &&
                                      alloc?.AllocationAmt !== undefined
                                        ? alloc?.AllocationAmt
                                        : allocatedObj[
                                            alloc[`${alloc?.CreditOrDebit}ID`]
                                          ] ??
                                          alloc?.BalanceAmt ??
                                          0
                                    }
                                    render={({ onChange, onBlur, value }) => (
                                      <NumberFormat
                                        thousandSeparator
                                        allowNegative={false}
                                        defaultValue={
                                          alloc?.AllocationAmt !== 0 &&
                                          alloc?.AllocationAmt !== undefined
                                            ? alloc?.AllocationAmt
                                            : allocatedObj[
                                                alloc[
                                                  `${alloc?.CreditOrDebit}ID`
                                                ]
                                              ] ??
                                              alloc?.BalanceAmt ??
                                              0
                                        }
                                        customInput={TextField}
                                        className="p-l-10 round-input"
                                        name={`${allocation}.AllocationAmt`}
                                        required
                                        variant="outlined"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        onValueChange={values => {
                                          let { floatValue } = values
                                          const indexData = filteredList.indexOf(
                                            filteredList?.find(
                                              x =>
                                                x?.CreditID === alloc?.CreditID
                                            )
                                          )
                                          filteredList[
                                            indexData
                                          ].AllocationAmt = floatValue
                                          register(
                                            `${allocation}.AllocationAmt`
                                          )
                                          setValue(
                                            `${allocation}.AllocationAmt`,
                                            floatValue
                                          )
                                          if (floatValue > alloc?.BalanceAmt) {
                                            setError(
                                              `${allocation}.AllocationAmt`,
                                              {
                                                message:
                                                  'Cannot exceed balance',
                                              }
                                            )
                                          } else {
                                            clearErrors(
                                              `${allocation}.AllocationAmt`
                                            )
                                          }
                                        }}
                                        helperText={
                                          errors.Allocation &&
                                          errors.Allocation[index]
                                            ?.AllocationAmt
                                            ? errors.Allocation[index]
                                                ?.AllocationAmt?.message
                                            : null
                                        }
                                        error={
                                          errors.Allocation &&
                                          errors.Allocation[index]
                                            ?.AllocationAmt
                                        }
                                      />
                                    )}
                                  />
                                </span>
                              </div>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={1} style={{ placeSelf: 'start' }} />
                        <Grid item xs={11} style={{ marginTop: '-15px' }}>
                          <div className="desc text-noflow">
                            {alloc?.Description}
                          </div>
                        </Grid>
                      </Grid>

                      <Divider
                        variant="fullWidth"
                        style={{ background: '#E4E4E4' }}
                      />
                    </>
                  )
                })}
              </div>
            </CardContents>
          </>
        ) : (
          <EmptyList
            title="No Credit Document found"
            subtitle="Add a new record in DN or Invoice Module."
          />
        )}
      </ContentWrapper>

      {footerOptions?.length > 0 ? (
        <AccountFooter options={[...footerOptions]} />
      ) : null}

      <TotalAmountFooter docAmt={getTotalAmount() ?? 0} />

      <CommonDialog
        fullWidth={true}
        open={openExitDialog}
        onClose={() => setopenExitDialog(false)}
        sections={{
          header: { title: 'Exit Confirmation' },
          body: () => (
            <div>
              Are you sure you want to exit? Your changes will not be saved.
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setopenExitDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => history.goBack(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-payable/${CompanyID}/batch-payment`)
        }}
      />
    </>
  )
}
