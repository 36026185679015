import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import React from 'react'
import { useHistory, useLocation } from 'react-router'

export const APConsolidatedEInvoiceReport = () => {
  const history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID
  let location = useLocation()
  const editData = location?.state as any

  const parameters = [
    {
      name: 'accountid',
      value: accountID,
    },
    {
      name: 'companyid',
      value: editData?.CompanyID,
    },
    {
      name: 'startdocdate',
      value: new Date(editData?.StartDocDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'enddocdate',
      value: new Date(editData?.EndDocDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'startconsolidateddate',
      value: editData?.StartConsolidatedDate
        ? new Date(
            editData?.StartConsolidatedDate.setHours(0, 0, 0)
          ).toISOString()
        : undefined,
    },
    {
      name: 'endconsolidateddate',
      value: editData?.EndConsolidatedDate
        ? new Date(
            editData?.EndConsolidatedDate.setHours(0, 0, 0)
          ).toISOString()
        : undefined,
    },
    {
      name: 'consolidateddocno',
      value: editData?.ConsolidatedDocNo,
    },
    {
      name: 'docno',
      value: editData?.DocNo,
    },
    {
      name: 'doctype',
      value: editData?.DocType,
    },
    {
      name: 'creditoraccountid',
      value: editData?.CreditorAccountID,
    },
  ]

  const generatedurl = (reportName, parameters) => {
    let reporturl = reportName

    parameters?.map((x, index) => {
      const symbol = index === 0 ? '?' : '&'
      if (x?.value !== undefined)
        reporturl = reporturl + symbol + x?.name + '=' + x?.value
    })
    return reporturl
  }

  return (
    <>
      <MainHeader
        onClick={() =>
          history.push(
            `/digital-reports/account-payable/ap-consolidated-einvoice/parameters`
          )
        }
        mainBtn="close"
        smTitle="Accounts Payable"
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Parameters' },
          { name: 'Consolidated E-Invoice', current: true },
        ]}
        rightRouteSegments={[{ name: 'Report', current: true }]}
      />
      <ContentWrapper style={{ maxHeight: '100%' }}>
        {ReportViewer({
          url: generatedurl('APConsolidatedEInvoiceReport', parameters),
          host: accountxReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
    </>
  )
}
