import { useEffect, useState } from 'react';
import {
  ModuleType,
  PoType,
  useGetGeneralDoLazyQuery,
  useGetGrtnHeaderLazyQuery,
  useGetPoHeaderPdfLazyQuery,
  useGetPrHeaderPdfLazyQuery,
  useGetRfqHeaderLazyQuery,
  DocumentType,
} from '../../generated/graphql';
import { useGetLetterTemplateByModuleLazyQuery } from '@account-root/account-react/src/generated/graphql';

export const useGPQueries: any = ({ docType, CompanyID, transactionID }) => {
  const [errMessage, setErrMessage] = useState(null);
  const [errDialog, setErrDialog] = useState(false);

  //----------GP Queries----------//
  const [
    fetchPRHeader,
    {
      loading: PRHeaderLoading,
      called: PRHeaderCalled,
      data: { getPRHeader } = { getPRHeader: [] },
    },
  ] = useGetPrHeaderPdfLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  const [
    fetchPOHeader,
    {
      loading: POHeaderLoading,
      called: POHeaderCalled,
      data: { getPOHeader } = { getPOHeader: [] },
    },
  ] = useGetPoHeaderPdfLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  const [
    fetchRFQHeader,
    {
      loading: RFQHeaderLoading,
      called: RFQHeaderCalled,
      data: { getRFQHeader } = { getRFQHeader: [] },
    },
  ] = useGetRfqHeaderLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  const [
    fetchGRTNHeader,
    {
      loading: GRTNHeaderLoading,
      called: GRTNHeaderCalled,
      data: { getGRTNHeader } = { getGRTNHeader: [] },
    },
  ] = useGetGrtnHeaderLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  const [
    fetchDOHeader,
    {
      loading: DOHeaderLoading,
      called: DOHeaderCalled,
      data: { getGeneralDO } = { getGeneralDO: [] },
    },
  ] = useGetGeneralDoLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  const [
    fetchLetterTemplate,
    {
      loading: LetterTemplateLoading,
      called: LetterTemplateCalled,
      data: { getLetterTemplateByModule } = { getLetterTemplateByModule: '' },
    },
  ] = useGetLetterTemplateByModuleLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  let queryLoading,
    queryCalled,
    listData,
    subMenuName,
    primaryKey,
    templateBody;

  switch (docType) {
    case 'purchase-requisition':
      queryLoading = PRHeaderLoading;
      queryCalled = PRHeaderCalled;
      listData = getPRHeader;
      subMenuName = 'Purchase Requisition';
      primaryKey = 'PRHeaderID';
      templateBody = getLetterTemplateByModule;
      break;
    case 'rfq':
      queryLoading = RFQHeaderLoading;
      queryCalled = RFQHeaderCalled;
      listData = getRFQHeader;
      subMenuName = 'Request for Quotation';
      primaryKey = 'RFQHeaderID';
      // templateBody = getLetterTemplateByModule
      break;
    case 'generate-po-from-pr':
      queryLoading = POHeaderLoading;
      queryCalled = POHeaderCalled;
      listData = getPOHeader;
      subMenuName = 'Generate PO from PR';
      primaryKey = 'POHeaderID';
      templateBody = getLetterTemplateByModule;
      break;
    case 'centralised-po':
      queryLoading = POHeaderLoading;
      queryCalled = POHeaderCalled;
      listData = getPOHeader;
      subMenuName = 'Centralised PO';
      primaryKey = 'POHeaderID';
      templateBody = getLetterTemplateByModule;
      break;
    case 'direct-po':
      queryLoading = POHeaderLoading;
      queryCalled = POHeaderCalled;
      listData = getPOHeader;
      subMenuName = 'Direct PO';
      primaryKey = 'POHeaderID';
      templateBody = getLetterTemplateByModule;
      break;
    case 'good-return-note':
      queryLoading = GRTNHeaderLoading;
      queryCalled = GRTNHeaderCalled;
      listData = getGRTNHeader;
      subMenuName = 'Goods Return Note';
      primaryKey = 'GRTNHeaderID';
      templateBody = getLetterTemplateByModule;
      break;
    case 'delivery-order':
      queryLoading = DOHeaderLoading;
      queryCalled = DOHeaderCalled;
      listData = getGeneralDO;
      subMenuName = 'Goods Receive Note';
      primaryKey = 'DOHeaderID';
      templateBody = getLetterTemplateByModule;
      break;
  }

  useEffect(() => {
    switch (docType) {
      case 'purchase-requisition':
        fetchPRHeader({
          variables: { PRHeaderID: transactionID, CompanyID },
        });
        fetchLetterTemplate({
          variables: {
            LetterCategory: ModuleType.Gp,
            LetterType: DocumentType.PurchaseRequisition,
            CompanyID,
          },
        });
        break;
      case 'rfq':
        fetchRFQHeader({
          variables: { RFQHeaderID: transactionID, CompanyID },
        });
        break;
      case 'generate-po-from-pr':
        fetchPOHeader({
          variables: {
            POHeaderID: transactionID,
            CompanyID,
            POType: PoType.PoFromPr,
          },
        });
        fetchLetterTemplate({
          variables: {
            LetterCategory: ModuleType.Gp,
            LetterType: DocumentType.GeneratePoFromPr,
            CompanyID,
          },
        });
        break;
      case 'centralised-po':
        fetchPOHeader({
          variables: {
            POHeaderID: transactionID,
            CompanyID,
            POType: PoType.CentralisedPo,
          },
        });
        fetchLetterTemplate({
          variables: {
            LetterCategory: ModuleType.Gp,
            LetterType: DocumentType.PurchaseOrder,
            CompanyID,
          },
        });
        break;
      case 'direct-po':
        fetchPOHeader({
          variables: {
            POHeaderID: transactionID,
            CompanyID,
            POType: PoType.DirectPo,
          },
        });
        fetchLetterTemplate({
          variables: {
            LetterCategory: ModuleType.Gp,
            LetterType: DocumentType.PurchaseOrder,
            CompanyID,
          },
        });
        break;
      // GRN and GRTN
      case 'good-return-note':
        fetchGRTNHeader({
          variables: { GRTNHeaderID: transactionID, CompanyID },
        });
        fetchLetterTemplate({
          variables: {
            LetterCategory: ModuleType.Gp,
            LetterType: DocumentType.GoodReturnNote,
            CompanyID,
          },
        });
        break;
      case 'delivery-order':
        fetchDOHeader({
          variables: { DOHeaderID: transactionID, CompanyID },
        });
        fetchLetterTemplate({
          variables: {
            LetterCategory: ModuleType.Gp,
            LetterType: DocumentType.GoodReceiveNote,
            CompanyID,
          },
        });
        break;
    }
  }, [docType]);

  return {
    queryLoading,
    queryCalled,
    listData,
    subMenuName,
    primaryKey,
    templateBody,
    errMessage,
    errDialog,
    setErrDialog,
  };
};
