import { List, ListItem, ListItemText } from '@material-ui/core'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { AllocatedDialog } from 'components/Dialog/AllocatedDialog'

export const DetailAllocationTableContent = (props: any) => {
  const { listItem, hasSequence, apSubmenu } = props
  const [allocDialog, setAllocDialog] = useState(false)
  const [allocArr, setAllocArr] = useState([])
  const [docNo, setDocNo] = useState('')
  const [balAmt, setBalAmt] = useState('')

  return (
    <>
      <div className="rm-padding table-wrap">
        <List className="core-list content-wrap full " disablePadding>
          {listItem === undefined || !listItem || listItem?.length === 0 ? (
            <EmptyList
              title="No Allocation found"
              subtitle="Add allocation from listing menu."
            />
          ) : (
            listItem?.map((el, index) => {
              return (
                <>
                  <ListItem
                    className="table-listItem "
                    key={index}
                    divider
                    style={{ backgroundColor: 'white' }}
                  >
                    <ListItemText
                      primary={
                        <>
                          {hasSequence === true && (
                            <span className="desc" style={{ width: '12px' }}>
                              {`${index + 1}.`}
                            </span>
                          )}

                          <span
                            className="desc date-width"
                            style={{ fontSize: '11px' }}
                          >
                            {formatDate(el?.[`${el?.CreditOrDebit}DocDate`])}
                          </span>
                          <span
                            className={`desc flex-space ${
                              apSubmenu === 'payment' ? 'click-text' : ''
                            }`}
                            style={{ fontSize: '11px', fontWeight: 700 }}
                            onClick={() => {
                              setAllocArr(el?.AllocatedDocs)
                              setDocNo(el?.[`${el?.CreditOrDebit}DocNo`])
                              setBalAmt(amtStr(el?.AllocationAmt))
                              setAllocDialog(true)
                            }}
                          >
                            {el?.[`${el?.CreditOrDebit}DocNo`]}
                          </span>
                          <span className="desc">
                            <span className="xsTitle">
                              {amtStr(el?.AllocationAmt)}
                            </span>
                          </span>
                        </>
                      }
                      secondary={
                        <>
                          <div>
                            <span
                              className="desc medium"
                              style={{ whiteSpace: 'pre-line' }}
                            >
                              {el?.Description}
                            </span>
                          </div>
                        </>
                      }
                    />
                  </ListItem>
                </>
              )
            })
          )}

          <AllocatedDialog
            allocDialog={allocDialog}
            setAllocDialog={setAllocDialog}
            docNo={docNo}
            BalAmt={balAmt}
            allocatedArr={allocArr}
            isPost={true}
          />
        </List>
      </div>
    </>
  )
}
